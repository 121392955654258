.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(800px);
  transition: all 500ms ease-in-out;
}
.ReactModal__Content::-webkit-scrollbar {
  width: 8px;
}
.ReactModal__Content::-webkit-scrollbar-track {
  background-color: #d9d9d9;
  border-radius: 17px;
  height: 8px;
}
.ReactModal__Content::-webkit-scrollbar-thumb {
  background-color: #60606c;
  border-radius: 17px;
  height: 8px;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(100px);
}

.formEditAdvisor .swal2-modal {
  z-index: 9999 !important;
}
